<template>
  <div>
    <sm-loader v-if="getRepaymentsData.loading" />
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold" v-else>
      <h2 class="text-2xl font-bold">Tangerine Repayments</h2>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="repayments.data"
        :columns="repayments.columns"
        :query="searchQuery"
        :loading="repayments.loading"
        :on-click="click"
        :selectable="true"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/loans/tangerine/repayments`"
        :search-field="searchField"
        :limit="30"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <div
      class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"
    >
      <div class="text-sm text-gray-700 mb-4">
        {{ selection.length }} Repayment{{ selection.length !== 1 ? 's' : '' }}
        Selected!
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-4">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <form-group
        type="select"
        :options="statusOptions"
        left-icon="business-outline"
        name="status"
        :form="form"
        :loading="repayments.loading"
        v-model="form.data.status.value"
      >
        Select Status
      </form-group>

      <div>
        <button
          class="btn btn-blue btn-sm mr-3"
          @click.prevent="payTangerineLife"
          :disabled="
            form.loading || !selection.length || !form.data.status.value
          "
        >
          <ion-icon
            name="checkmark-done-outline"
            class="text-lg mr-2"
          ></ion-icon>
          <span v-if="form.loading">Marking</span>
          <span v-else>Mark as {{ form.data.status.value }}</span>
        </button>
        <button
          class="btn btn-gray btn-sm"
          @click.prevent="clear"
          :disabled="form.loading || !selection.length"
        >
          <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
          <span>Clear Selection</span>
        </button>
      </div>
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    },
    lenders: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      repayments: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: 'loan_id',
            th: 'Loan ID'
          },
          {
            name: 'id',
            th: 'Lender Rpy ID'
          },
          {
            name: 'user',
            th: 'Name',
            render: repayment =>
              `${repayment?.loan.user?.name} ${repayment?.loan.user
                ?.last_name || 'N/A'}`
          },
          {
            name: 'batch',
            th: 'Batch'
          },
          {
            name: 'principal_amount',
            th: 'Principal Amount',
            render: repayment =>
              `₦ ${this.$options.filters.currency(repayment?.principal_amount)}`
          },
          {
            name: 'interest_amount',
            th: 'Interest Amount',
            render: repayment =>
              `₦ ${this.$options.filters.currency(repayment?.interest_amount)}`
          },
          {
            name: 'repayment_amount',
            th: 'Repayment Amount',
            render: repayment =>
              `₦ ${this.$options.filters.currency(repayment?.repayment_amount)}`
          },
          {
            name: 'due_date',
            th: 'Due Date'
          },
          {
            name: 'lender_status',
            th: 'Lender Status',
            render: repayment => {
              const today = this.$moment().format('YYYY-MM-DD');
              switch (repayment.lender_status) {
                case 'paid':
                  return `<div class="badge badge-green-soft-outline">
                        Paid
                      </div>`;
                case 'transferred':
                  return `<div class="badge badge-green-soft-outline">
                        Transferred
                      </div>`;
                case 'unpaid':
                  if (today > repayment.due_date) {
                    return `<div class="badge badge-orange-soft-outline">
                        Due
                      </div>`;
                  }
                  return `<div class="badge badge-blue-soft-outline">
                        Unpaid
                      </div>`;
                case 'not_due':
                  if (today > repayment.due_date) {
                    return `<div class="badge badge-orange-soft-outline">
                        Due
                      </div>`;
                  }
                  return `<div class="badge badge-blue-soft-outline">
                        Not Due
                      </div>`;
                case 'due':
                  if (repayment.default_charge) {
                    return `<div class="badge badge-red-soft-outline">
                        Defaulting
                      </div>`;
                  }
                  return `<div class="badge badge-orange-soft-outline">
                        Due
                      </div>`;
                case 'processing':
                  return `<div class="badge badge-blue-soft-outline">
                        Processing
                      </div>`;
                case 'failed':
                  return `<div class="badge badge-red-soft-outline">
                        Failed
                      </div>`;
                default:
                  if (repayment.defaulted) {
                    return `<div class="badge badge-red-soft-outline">
                          Default
                        </div>`;
                  }
                  break;
              }
            }
          },
          {
            name: 'credpal_status',
            th: 'Credpal Status',
            render: repayment => {
              const today = this.$moment().format('YYYY-MM-DD');
              switch (repayment.credpal_status) {
                case 'paid':
                  return `<div class="badge badge-green-soft-outline">
                        Paid
                      </div>`;
                case 'transferred':
                  return `<div class="badge badge-green-soft-outline">
                        Transferred
                      </div>`;
                case 'not_due':
                  if (today > repayment.due_date) {
                    return `<div class="badge badge-orange-soft-outline">
                        Due
                      </div>`;
                  }
                  return `<div class="badge badge-blue-soft-outline">
                        Not Due
                      </div>`;
                case 'unpaid':
                  if (today > repayment.due_date) {
                    return `<div class="badge badge-orange-soft-outline">
                        Due
                      </div>`;
                  }
                  return `<div class="badge badge-blue-soft-outline">
                        Unpaid
                      </div>`;
                case 'due':
                  if (repayment.default_charge) {
                    return `<div class="badge badge-red-soft-outline">
                        Defaulting
                      </div>`;
                  }
                  return `<div class="badge badge-orange-soft-outline">
                        Due
                      </div>`;
                case 'processing':
                  return `<div class="badge badge-blue-soft-outline">
                        Processing
                      </div>`;
                case 'failed':
                  return `<div class="badge badge-red-soft-outline">
                        Failed
                      </div>`;
                default:
                  if (repayment.defaulted) {
                    return `<div class="badge badge-red-soft-outline">
                          Default
                        </div>`;
                  }
                  break;
              }
            }
          }
        ],
        successes: [],
        failures: []
      }),
      form: this.$options.basicForm(['status']),
      getRepaymentsData: this.$options.resource({})
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    statusOptions() {
      return [
        { title: 'paid', value: 'paid' },
        { title: 'transfered', value: 'transfered' }
      ];
    },
    selectedUser() {
      return this.repayments.selected?.loan?.user;
    },
    getLender() {
      const lender = this.lenders.data.find(
        lender => lender.name === 'TangerineLife'
      );
      return lender?.id || null;
    }
  },
  beforeMount() {
    // this.getLoans();
    // this.getLenders();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(repayment) {
      this.repayments.selected = repayment;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    getRepayments() {
      this.$refs.table.loadAjaxData();
    },
    async payTangerineLife() {
      if (!this.validateForm(this.form)) {
        return false;
      }
      this.repayments.failures = [];

      this.form.loading = true;
      this.form.error = null;
      await this.sendRequest('admin.loans.personal.pay_tangerine', {
        data: {
          repayments: this.selection.map(item => item.row.id),
          status: this.form.data.status.value,
          lender: this.getLender
        },
        success: () => {
          this.$success({
            title: 'Repayment Marked as Paid!',
            body: 'You will be sent an email if there was an error',
            button: 'Okay'
          });
          this.clear();
          this.getRepayments();
        },
        error: error => {
          this.form.error = error;
          if (error?.response?.data?.failures) {
            this.repayments.failures = error?.response?.data?.failures;
          }
        }
      });
      this.form.loading = false;
    }
  }
};
</script>
